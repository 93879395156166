import React from "react";
import PropTypes from "prop-types";
import { Stack, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

/**
 * EndAdornment component for Autocomplete, extended with add button
 * @return {JSX.Element}
 */
const EndAdornment = ({
    onAddButtonClick,
    addButtonId,
    addButtonTooltip,
    defaultEndAdornment,
}) => {
    return (
        <Stack direction="row" spacing={2} alignItems={"center"}>
            {defaultEndAdornment}
            <Tooltip title={addButtonTooltip} placement="top">
                <IconButton
                    id={addButtonId}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onAddButtonClick(event);
                    }}
                    sx={{
                        width: "38px",
                        height: "38px",
                        borderRadius: "3px",
                        background: "rgba(0, 0, 0, 0.05)",
                        "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                        },
                    }}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

EndAdornment.propTypes = {
    onAddButtonClick: PropTypes.func,
    addButtonId: PropTypes.string,
    addButtonTooltip: PropTypes.string,
    defaultEndAdornment: PropTypes.element,
};

export default EndAdornment;
