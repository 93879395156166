import React, { createContext, useContext, useState } from "react";

const defaultValue = {
    loading: false,
    alertMsg: null,
    alertDialogs: [],
    setLoading: () => {},
    setAlertMsg: () => {},
};

const MuiPageContext = createContext(defaultValue);

/**
 * Context provider for handling state of MuiPage shared component
 * @return {JSX.Element}
 */
export const MuiPageProvider = ({ children }) => {
    const [loading, setLoading] = useState(defaultValue.loading);
    const [alertMsg, setAlertMsg] = useState(defaultValue.alertMsg);
    const [alertDialogs, setAlertDialogs] = useState(defaultValue.alertDialogs);

    /**
     * Add alert dialog element
     * @param {object} alertDialogProps
     */
    const addAlertDialogElement = (alertDialogProps) => {
        setAlertDialogs((prevState) => [...prevState, alertDialogProps]);
    };

    /**
     * Add alert dialog element(s)
     * @param {object | Array.object} alertDialogProps
     */
    const addAlertDialog = (alertDialogProps) => {
        if (Array.isArray(alertDialogProps)) {
            alertDialogProps.forEach((alertElement) =>
                addAlertDialogElement(alertElement),
            );
            return;
        }

        if (typeof alertDialogProps === "object") {
            addAlertDialogElement(alertDialogProps);
        }
    };

    /**
     * Remove alert dialog element
     * @param {object} alertDialogProps
     */
    const removeAlertDialog = (alertDialogProps) => {
        setAlertDialogs((prevState) => {
            const newState = [...prevState];
            const index = newState.findIndex(
                (alert) => alert === alertDialogProps,
            );
            if (index !== -1) {
                newState.splice(index, 1);
                return newState;
            }

            return prevState;
        });
    };

    /**
     * removeAllAlertDialog
     */
    const removeAllAlertDialog = () => {
        setAlertDialogs([]);
    };

    const ctx = {
        loading,
        alertMsg,
        alertDialogs,
        setLoading,
        setAlertMsg,
        addAlertDialog,
        removeAlertDialog,
        removeAllAlertDialog,
    };

    return (
        <MuiPageContext.Provider value={ctx}>
            {children}
        </MuiPageContext.Provider>
    );
};

export default MuiPageProvider;

/**
 * Hook for using provider
 * @return {object}
 */
export const useMuiPageProvider = () => useContext(MuiPageContext);
