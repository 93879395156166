import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import translate from "flow-core-library-translator";

import t from "../../translations.json";
import EndAdornment from "./EndAdornment/EndAdornment";

/** Input field for Autocomplete
 * @return {JSX.Element}
 */
const InputField = ({
    label,
    error,
    helperText,
    onAddButtonClick,
    ...params
}) => {
    return (
        <TextField
            label={label}
            error={error}
            helperText={helperText}
            {...params}
            sx={{
                ...params.xs,
                ...(onAddButtonClick
                    ? /* prettier-ignore */ {
                        "& .MuiAutocomplete-endAdornment": {
                            position: "static",
                            transform: "initial"
                        },
                    }
                    : {}),
            }}
            InputProps={{
                ...params.InputProps,
                ...(onAddButtonClick
                    ? /* prettier-ignore */ {
                        endAdornment: (
                            <EndAdornment
                                defaultEndAdornment={params.InputProps.endAdornment}
                                addButtonId={params.id ? `${params.id}_btnAdd`: undefined}
                                addButtonTooltip={translate(t, "autocomplete.addNewItem")}
                                onAddButtonClick={()=>onAddButtonClick(params.inputProps.value || "")}
                            />
                        ),
                    }
                    : {}),
            }}
        />
    );
};

InputField.propTypes = {
    error: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    label: PropTypes.string,
    onAddButtonClick: PropTypes.func,
};

export default InputField;
