import React from "react";
import { AlertDialog } from "@flow-frontend-npm/general";
import { useMuiPageProvider } from "../../../provider/MuiPageProvider/MuiPageProvider";
import translate from "flow-core-library-translator";
import translations from "./translations.json";

// eslint-disable-next-line require-jsdoc
const t = (key) => translate(translations, key);

/**
 * Display alert dialogs
 * @return {JSX.Element}
 */
const AlertDialogs = () => {
    const { alertDialogs, removeAlertDialog } = useMuiPageProvider() || {
        alertDialogs: null,
        removeAlertDialog: () => {},
    };

    if (!alertDialogs || !Array.isArray(alertDialogs)) {
        return null;
    }

    return (
        <>
            {alertDialogs.map((alertDialogProps, index) => {
                const title = alertDialogProps.title ||
                    t(`title.${alertDialogProps.severity.toLowerCase()}`) ||
                    "";
                return (
                    <AlertDialog
                        open
                        key={`alertDialog_${index}`}
                        id={`alertDialog${index}`}
                        hideBackdrop={index !== 0}
                        title={title}
                        {...alertDialogProps}
                        severity={alertDialogProps.severity.toLowerCase()}
                        onClose={(confirmed) => {
                            if(alertDialogProps.onClose && typeof alertDialogProps.onClose === "function") {
                                alertDialogProps.onClose(confirmed);
                            }
                            removeAlertDialog(alertDialogProps);
                        }}
                    />
                );
            })}
        </>
    );
};

AlertDialogs.propTypes = {};

export default AlertDialogs;
