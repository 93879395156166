import React, { forwardRef } from "react";
import { PropTypes } from "prop-types";
import MuiAutocomplete from "@mui/material/Autocomplete";

import InputField from "./InputField/InputField";

/**
 * Enhanced Autocomplete component for Flow, simplify to use Mui Autocomplete
 * @param {string} label Label text for input element
 * @param {string} name This value used at onChange event call as second paramter
 * @param {string} optionLabelPropName Name of the object property in option element, which should be used as option label
 * @param {string} optionValuePropName Name of the value property in option element. The default is 'id'.
 *      It could be necessary when the value prop name is not the default in value and option objects.
 * @param {function} onChange This event function is called when an option item selected
 * @param {function} onInputChange This event function is called when typing in the input search field
 * @param {boolean} error If true, the input field indicates error
 * @param {string} helperText Helper text below the input field in case of error
 * @param {boolean} stringMode If it is true, this component can receive and send (at onChange) string typed value,
 *      instead of the default (object), such as the Select component. At default, the Mui Autocomplete component works with
 *      object value. When this mode is used, it can have performance affect because of computing values and the optionValuePropName
 *      prop is required.
 * @param {Array.object} options Option item list for the dropdown
 * @param {object | string} value Value of the component
 * @param {object} inputProps Additional props for input field
 * @param {function} onAddButtonClick If it is defined, Add icon button will be displayed in the input component
 *      and run this callback on click event
 * @return {JSX.Element}
 */
const Autocomplete = forwardRef(function Autocomplete(
    {
        label,
        name,
        optionLabelPropName,
        onChange,
        onInputChange,
        error,
        helperText,
        optionValuePropName = "id",
        stringMode = false,
        value,
        options,
        inputProps,
        onAddButtonClick,
        ...rest
    },
    ref
) {
    return (
        <MuiAutocomplete
            {...rest}
            ref={ref}
            fullWidth
            disablePortal
            getOptionLabel={(option) => option[optionLabelPropName]}
            renderInput={(params) => (
                <InputField
                    {...params}
                    label={label}
                    error={error}
                    helperText={helperText}
                    onAddButtonClick={onAddButtonClick}
                    id={rest.id}
                    {...inputProps}
                />
            )}
            isOptionEqualToValue={(option, value) => {
                if (value) {
                    return (
                        option[optionValuePropName] ===
                        value[optionValuePropName]
                    );
                }
            }}
            onChange={(event, value) =>
                onChange(
                    stringMode
                        ? value
                            ? value[optionValuePropName]
                            : null
                        : value,
                    name
                )
            }
            onInputChange={
                onInputChange &&
                ((event, newInputValue, reason) => {
                    if (reason === "input") {
                        onInputChange(newInputValue);
                    }
                })
            }
            filterOptions={(options, { inputValue, getOptionLabel }) => {
                /* Prevent filtering when the search input value starts with _ character.
                   Without this the autocomplete filters the options based on _ characters and hides options. */
                if (["_", "__", "___"].includes(inputValue)) {
                    return options;
                }

                return options.filter(
                    (option) =>
                        getOptionLabel(option)
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                );
            }}
            value={
                /* prettier-ignore */
                stringMode
                    ? options.find(
                        (option) => option[optionValuePropName] === value
                    ) || null
                    : value
            }
            options={options}
            sx={{
                ...rest.sx,
                ...(onAddButtonClick
                    ? /* prettier-ignore */ {
                        "& .MuiAutocomplete-inputRoot": {
                            p: "9px !important",
                        },
                    }
                    : {}),
            }}
        />
    );
});

Autocomplete.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    optionLabelPropName: PropTypes.string,
    optionValuePropName: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    stringMode: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inputProps: PropTypes.object,
    onAddButtonClick: PropTypes.func,
};

export default Autocomplete;
