import React, { memo, useEffect, useCallback, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { Grid, Stack, Button } from "@mui/material";

import { backgroundColor } from "../consts";
import ChangeViewButton from "../ChangeViewButton/ChangeViewButton";

/**
 * Buttons section
 * @return {JSX.Element}
 */
const ButtonsSection = ({
    breakpoints,
    buttons,
    stickyButtons,
    bottomNote,
    isThereAdditionalSection,
    advancedSearch,
    setAdvancedSearch,
}) => {
    return (
        <>
            {/* Placeholder on left side */}
            <Grid item {...breakpoints[0]} sx={{ p: "0 !important" }} />

            {/* Bottom not and buttons */}
            <Grid
                item
                {...breakpoints[1]}
                sx={{
                    pb: 3,
                    position: "sticky",
                    // prettier-ignore
                    ...(stickyButtons
                        ? {
                            bottom: 3,
                            backgroundColor,
                            zIndex: 1,
                        }
                        : {}),
                }}
            >
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm>
                        {!!bottomNote && bottomNote}
                    </Grid>
                    <Grid item xs={12} sm>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="end"
                            alignItems="flex-end"
                        >
                            {/* Display "more search options" button only when there are additional sections */}
                            {isThereAdditionalSection && (
                                <ChangeViewButton
                                    advancedSearch={advancedSearch}
                                    onClick={() =>
                                        setAdvancedSearch(!advancedSearch)
                                    }
                                />
                            )}
                            {buttons.map(
                                (
                                    { 
                                        label,
                                        disabled,
                                        onClick,
                                        sx,
                                        disabletimeout,
                                        ...rest
                                    },
                                    key,
                                ) => {
                                    const ButtonUsed = typeof disabletimeout !== 'undefined' && disabletimeout > 0 
                                        ? ButtonTimeout 
                                        : Button;

                                    return <ButtonUsed
                                        key={`formButton_${key}`}
                                        disabled={disabled}
                                        onClick={onClick}
                                        variant="contained"

                                        disabletimeout={disabletimeout}

                                        sx={{
                                            textDecoration: "none",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            lineHeight: "26px",
                                            letterSpacing: "0.46px",
                                            width: {
                                                xs: "100%",
                                                sm: "initial",
                                            },
                                            ...sx,
                                        }}
                                        {...rest}
                                    >
                                        {label}
                                    </ButtonUsed>;
                                },
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {/* Placeholder on right side */}
            <Grid item {...breakpoints[2]} />
        </>
    );
};

const ButtonTimeout = memo(forwardRef(({
    disabled,
    onClick,
    disabletimeout,
    children,
    ...rest
}, ref) => {
    const [loading, setLoading] = useState(false);

    const onClickWrapper = useCallback(() => {
        if(!loading){
            setLoading(true);
            onClick();
        }
    }, [loading, setLoading, onClick]);

    useEffect(() => {
        if(loading){
            const timer1 = setTimeout(() => setLoading(false), disabletimeout);
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [loading, setLoading, disabletimeout]);

    return <Button
        ref={ref}
        
        disabled={loading || disabled}
        onClick={onClickWrapper}
        
        {...rest}
    >
        {children}
    </Button>;
}));
ButtonTimeout.displayName = 'ButtonTimeout';
ButtonTimeout.propTypes = {
    disabled: PropTypes.bool,
    disabletimeout: PropTypes.number,
    onClick: PropTypes.func,
    children: PropTypes.any
};

ButtonsSection.propTypes = {
    breakpoints: PropTypes.arrayOf(PropTypes.object),
    buttons: PropTypes.arrayOf(PropTypes.object),
    stickyButtons: PropTypes.bool,
    bottomNote: PropTypes.element,
    isThereAdditionalSection: PropTypes.bool,
    advancedSearch: PropTypes.bool,
    setAdvancedSearch: PropTypes.func,
};

export default ButtonsSection;
