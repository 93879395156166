import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from "react";
import ReactDOM from "react-dom";
import DrivePreregistration from "./src/DrivePreregistration";

import "./index.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";

ReactDOM.render(
    <DrivePreregistration />,
    document.getElementById("flow-drivepreregistration")
);
