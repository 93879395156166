import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import HelpIcon from "@mui/icons-material/Help";
import translate from "flow-core-library-translator";

import t from "../translations.json";

/**
 * Generic, reusable Mui dialog component with multiple variants
 * (info, warning, confirmation)
 * @param {boolean} open The dialog is opened or not
 * @param {string} title Title text of the dialog
 * @param {string | arrayOf.string} text Text content of the dialog. In case of array value, every element will displayed in a new line
 * @param {info | warning | confirmation} variant The icon and buttons will be determined based on this value
 * @param {function} onClose This is emitted on button press
 * @param {string} okButtonCaption Caption for ok button in case of confirmation dialog
 * @param {string} cancelButtonCaption Caption for cancel button in case of confirmation dialog
 * @return {JSX.Element}
 */
const Dialog = ({
    open,
    title,
    text,
    variant,
    onClose,
    okButtonCaption,
    cancelButtonCaption,
    ...rest
}) => {
    const DialogProps = useMemo(() => {
        const defaults = {
            icon: null,
            buttons: [
                {
                    children: translate(t, "dialog.ok"),
                    onClick: onClose,
                    variant: "contained",
                    color: "primary",
                },
            ],
        };

        if (variant === "info") {
            return { ...defaults, icon: InfoIcon };
        }

        if (variant === "warning") {
            return { ...defaults, icon: WarningIcon };
        }

        if (variant === "confirmation") {
            return {
                ...defaults,
                buttons: [
                    {
                        children: okButtonCaption || translate(t, "dialog.ok"),
                        onClick: (event) => onClose(event, true),
                        variant: "contained",
                        color: "primary",
                    },
                    {
                        children:
                            cancelButtonCaption ||
                            translate(t, "dialog.cancel"),
                        onClick: (event) => onClose(event, false),
                        variant: "outlined",
                        color: "primary",
                    },
                ],
                icon: HelpIcon,
            };
        }

        return defaults;
    }, [variant]);

    return (
        <MuiDialog open={open} {...rest}>
            <DialogTitle sx={{ pt: 2 }}>{title}</DialogTitle>
            <DialogContent>
                <Stack direction="row" spacing={3}>
                    {DialogProps.icon && (
                        <Icon
                            sx={{
                                color: ({ palette }) => palette.text.secondary,
                            }}
                            fontSize="large"
                            component={DialogProps.icon}
                        />
                    )}
                    <div>
                        {Array.isArray(text) ? (
                            text.map((textElement, index) => (
                                <Typography key={`dialogText_${index}`}>
                                    {textElement}
                                </Typography>
                            ))
                        ) : (
                            <Typography>{text}</Typography>
                        )}
                    </div>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Stack direction="row" spacing={2}>
                    {DialogProps.buttons.map((buttonProps, index) => (
                        <Button
                            key={`dialogButton_${index}`}
                            {...buttonProps}
                        />
                    ))}
                </Stack>
            </DialogActions>
        </MuiDialog>
    );
};

Dialog.propTypes = {
    open: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    onClose: PropTypes.func,
    title: PropTypes.string,
    variant: PropTypes.oneOf(["info", "warning", "confirmation"]),
    okButtonCaption: PropTypes.string,
    cancelButtonCaption: PropTypes.string,
};

export default Dialog;
