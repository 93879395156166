import get from 'lodash-es/get';
import baseDictionary from './baseDictionary';
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import deLocale from "i18n-iso-countries/langs/de.json";

// globalDefinition of the LANGUAGE
// allowed languages
export const LANGUAGES = {
    'DE': 'de',
    'EN': 'en',
};

export const LANGUAGE_BROWSER = navigator.language;
export const LANGUAGE_ISO2 = navigator.language.toLowerCase().substring(0, 2);
export const LANGUAGE_NAMES = new Intl.DisplayNames([LANGUAGE_ISO2], {type: 'language'});

/**
 * Get allowed language by browser language
 *
 * @param   {string}    lang_iso2
 * @param   {array}    languages
 * @return {string}
 */
export const getLanguage = (lang_iso2, languages = LANGUAGES) => {
    for(let key in languages){
        if(lang_iso2 === languages[key]){ return lang_iso2 }
    }
    // default
    return languages.DE;
};

/**
 * Detect preferred language based on browser language settings
 * @return {string}
 */
export const detectLanguage = () => {
    return getLanguage(LANGUAGE_ISO2, LANGUAGES);
};

export const LANGUAGE = detectLanguage();

/**
 * translate
 * @param   {object}    source
 * @param   {string}    path
 * @param   {object}    [replacements]
 * @return  {string}
 *      the translation (if not found, returns i18n-path: <language-code>.<value of parameter 'path'>)
 */
export const translate = (source, path, replacements = null) => {
    const i18nPath = LANGUAGE + '.' + path;
    let text = get(
        source ? source : baseDictionary,
        i18nPath,
        i18nPath // fall back to path if nothing could be found
    );
    if (text === i18nPath) { // in case of 'get()' was not able to find something
        console.warn(`[translator] Path "${i18nPath}" could not be found.`); // eslint-disable-line
    }
    if (replacements) { // replacements = { replaceThis: 'with this', andThis: 'with this one' }
        text = Object.keys(replacements).reduce((acc, key) => { // key = 'replaceThis', src = ['replaceThis', 'andThis']
            const regexp = new RegExp(`%%(${key})%%`, 'g');
            return acc.replace(regexp, replacements[key]);
        }, text);
    }
    return text;
};

/**
 * Translates language-code into language-name.
 * @param {string} code
 * @return {string}
 */
export const translateIso2LanguageCode = code => code && LANGUAGE_NAMES.of(code.toLowerCase());

const DATE_FORMAT_OPTIONS = { dateStyle: 'medium' };
const DATETIME_FORMAT_OPTIONS = { dateStyle: 'medium', timeStyle: 'short' };

/**
 * Formats date in local-appriopriate representation.
 * @param {date} date
 * @return {string}
 */
export const translateDate = date => (
    date && date.toLocaleString && date.toLocaleString(LANGUAGE_BROWSER, DATE_FORMAT_OPTIONS)
);

/**
 * Formats date+time in local-appriopriate representation.
 * @param {date} date
 * @return {string}
 */
export const translateDateTime = date => (
    date && date.toLocaleString && date.toLocaleString(LANGUAGE_BROWSER, DATETIME_FORMAT_OPTIONS)
);

/**
 * formats numbers by adding language specific separators
 *
 * @param   {number|string}     amount          raw number
 * @param   {string}            defaultValue    string to fall back if formatting fails
 * @param   {boolean}           replaceZero     switch to replace zero-amounts with defaultValue
 * @return  {string}            formatted       string of language specific number
 */
export const formatInteger = (amount, defaultValue = '', replaceZero = false) => {
    if (
        // check if 'amount' is actually a number
        Number.isNaN(Number(amount)) ||
        // check if 'amount' is present
        amount === '' ||
        // check if 'replaceZero' logic should kick in
        (Number(amount) === 0 && replaceZero)
    ) {
        return defaultValue;
    }
    const roundedAmount = Math.round(Number(amount));
    const divider = {
        de: '.',
        en: ','
    };
    return `${roundedAmount}` // convert to string
        .split('')
        .reverse()
        .map((item, index, array) => {
            return !((index + 1) % 3) && index !== (array.length - 1) ? divider[LANGUAGE_ISO2] + item : item;
        })
        .reverse()
        .join('');
};

/**
 * Translate country names in an object array, based on country codes
 * @param {Array.object} input Object array to translate
 * @param {string} inputLang Language of the input array to decide translation is necessary or not. Default: "de"
 * @param {string} codeKey Name of the property, which contains the code of the country in the input object element. Default: "code"
 * @param {string} nameKey Name of the property, which contains the name of the country in the input object element. Default: "name"
 * @return {Array.object}
 */
export const translateCountryNames = (
    input,
    inputLang = "de",
    codeKey = "code",
    nameKey = "name"
) => {
    // When the inputLang equals with browser language, do nothing, don't translate
    if (inputLang === LANGUAGE_ISO2) {
        return input;
    }

    // Translations
    let locale;
    if (LANGUAGE_ISO2 === "de") {
        locale = deLocale;
    } else if (LANGUAGE_ISO2 === "en") {
        locale = enLocale;
    } else {
        locale = enLocale;
    }
    countries.registerLocale(locale);

    const translatedCountryNames = countries.getNames(LANGUAGE_ISO2 || "en", {
        select: "official",
    });

    return input.map((countryItem) => ({
        [codeKey]: countryItem[codeKey],
        [nameKey]:
            translatedCountryNames[countryItem[codeKey]] ||
            countryItem[nameKey],
    }));
};
