import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { usePickerLayout } from "@mui/x-date-pickers/PickersLayout";
import translate from "flow-core-library-translator";

import translations from "../../../translations.json";

// eslint-disable-next-line require-jsdoc
const t = (key) => translate(translations, `dateTimePicker.buttons.${key}`);

/**
 * Action buttons component
 * @param {object} layoutProps
 * @return {JSX.Element}
 */
const ActionButtons = ({ layoutProps }) => {
    const { actionBar } = usePickerLayout(layoutProps);

    return (
        <>
            <Button onClick={actionBar.props.onCancel}>{t("cancel")}</Button>
            <Button onClick={actionBar.props.onAccept}>{t("ok")}</Button>
        </>
    );
};

ActionButtons.propTypes = {
    layoutProps: PropTypes.object,
};

export default ActionButtons;
