import {FC, ReactElement, ReactNode} from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import Backdrop from "./Backdrop";
import PageHeader from "./PageHeader/PageHeader";
import Alert from "./Alert/Alert";
import AlertDialogs from "./AlertDialogs/AlertDialogs";

export const containerStyle = {
    default: {
        mt: 2.5,
        mb: 2.5,
        ml: 7.5,
        mr: 7.5,
    },
    flow5: {
        p: {
            xs: 2,
            md: 3,
            lg: 6,
        },
    },
};

interface MuiPageProps {
    variant: "default" | "flow5";
    headerProps: {
        title: string | ReactNode;
        toolbar?: ReactNode;
    };
    /*
     * Custom component for displaying and wrapping header
     */
    HeaderComponent?: FC<{children: ReactNode}>;
    /*
     * Hide the top divider
     */
    hideTopDivider?: boolean;
    /*
     * Optional footer component to the bottom of the page
     */
    footer?: ReactNode;
    /*
     * Set loading state of the page to display circular progress animation on dark background
     */
    loading?: boolean;
    /*
     * If it is true, mobile icon will be placed in title for marking the page is optimized for mobile too
     */
    responsive?: boolean;
    /*
     * Page alert message(s) to display
     */
    alert?: string | object | []
    children: ReactNode
}

/**
 * Sets up common stlye configurtion and header component for pages, implementing a new MUI layout.
 * Handle variants: default (legacy style) and flow5 (new style for search forms)
 */
const MuiPage: FC<MuiPageProps> = ({
    variant = "default",
    headerProps,
    HeaderComponent,
    hideTopDivider,
    footer,
    loading = false,
    responsive = false,
    alert,
    children
}): ReactElement => {
    const Header: ReactNode = headerProps ? (
        <PageHeader {...headerProps} responsive={responsive} />
    ) : null;

    return (
        <>
            {variant === "flow5" && !hideTopDivider && <Divider />}
            {variant === "flow5" && <Backdrop open={loading} />}
            <AlertDialogs />
            <Box
                sx={{
                    ...(footer ? { display: "flex", minHeight: "100vh" } : {}),
                    ...(containerStyle[variant] || containerStyle["default"]),
                }}
            >
                <Stack
                    direction="column"
                    alignItems="stretch"
                    spacing={3}
                    sx={footer ? { flex: 1 } : {}}
                >
                    {/* HEADER */}
                    {HeaderComponent ? (
                        <HeaderComponent>{Header}</HeaderComponent>
                    ) : (
                        Header
                    )}

                    {/* ALERT SECTION */}
                    {alert && <Alert alert={alert} />}

                    {/* BODY */}
                    <Box sx={footer ? { flex: 1 } : {}}>{children}</Box>

                    {/* FOOTER */}
                    {!!footer && footer}
                </Stack>
            </Box>
        </>
    );
};

export default MuiPage;
export { default as ResponsivePageIcon } from "./PageHeader/ResponsiveIcon";
