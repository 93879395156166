import React, { useState, useMemo } from "react";
import { PropTypes } from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import SectionRow from "./SectionRow/SectionRow";
import ButtonsSection from "./ButtonsSection/ButtonsSection";
import { backgroundColor, defaultSectionRowBreakpoints } from "./consts";

export const formInputCommonStyle = {
    "& .MuiInputBase-root": {
        backgroundColor: "#FFFFFF",
    },
};

export const defaultFormFieldSpacing = 2;

/**
 * @typedef {object} Props
 * @property {Array.<object>} sections Sections of the form, which should be displayed with label and divider on the bottom
 * @property {Array.<object>} buttons Buttons of the form
 * @property {function} onSubmit Call this function onSubmit event
 * @property {JSX.Element} bottomNote Bottom note component in the same line as buttons
 * @property {boolean} stickyButtons If it is enabled the button row will be sicky on scrolling
 * @property {array<Object>} breakpointOverrides Override breakpoints of SectionRows
 */

/**
 * Reusable generic Form Layout component in order to keep style consistent
 * It displays the form in sections, display/hide advanced search sections
 * Displays buttons at the bottom of the form
 * @param {Props} props
 * @return {JSX.Element}
 */
const FormLayout = ({
    sections,
    buttons,
    onSubmit,
    bottomNote,
    stickyButtons = false,
    breakpointOverrides,
}) => {
    // Advanced form sections is displayed or not
    const [advancedSearch, setAdvancedSearch] = useState(false);

    // Get the sections which should be dispayed initialy (not additional)
    const sectionsToDisplay = useMemo(
        () =>
            sections.filter(({ additional }) => !additional || advancedSearch),
        [advancedSearch, sections],
    );

    // Check sections if there is additional element
    const isThereAdditionalSection = useMemo(
        () => sections.findIndex((section) => section.additional) !== -1,
        [sections],
    );

    const breakpoints = breakpointOverrides || defaultSectionRowBreakpoints;

    return (
        <Box
            sx={{
                backgroundColor,
                borderRadius: "12px",
                px: 3,
                pt: 3,
            }}
        >
            <form autoComplete="off" onSubmit={onSubmit}>
                <Grid container direction="row" spacing={3}>
                    {/* Display sections */}
                    {sectionsToDisplay.map(({ label, content }, index) => (
                        <SectionRow
                            breakpoints={breakpoints}
                            key={index}
                            label={label}
                            content={content}
                            noDivider={index + 1 === sectionsToDisplay.length}
                        />
                    ))}

                    {/* Buttons section */}
                    <ButtonsSection
                        breakpoints={breakpoints}
                        stickyButtons={stickyButtons}
                        buttons={buttons}
                        bottomNote={bottomNote}
                        isThereAdditionalSection={isThereAdditionalSection}
                        advancedSearch={advancedSearch}
                        setAdvancedSearch={setAdvancedSearch}
                    />
                </Grid>
            </form>
        </Box>
    );
};

FormLayout.propTypes = {
    onSubmit: PropTypes.func,
    bottomNote: PropTypes.element,
    stickyButtons: PropTypes.bool,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            additional: PropTypes.bool,
            label: PropTypes.string,
            content: PropTypes.element,
        }),
    ),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            disabled: PropTypes.bool,
            onClick: PropTypes.func,
        }),
    ),
    breakpointOverrides: PropTypes.arrayOf(PropTypes.object),
};

export default FormLayout;
