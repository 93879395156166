import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Divider } from "@mui/material";

/**
 * Display title and input field of a form row
 * @return {JSX.Element}
 */
const SectionRow = ({ label, content, noDivider, breakpoints }) => {
    return (
        <>
            <Grid item {...breakpoints[0]}>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "160%",
                        letterSpacing: "0.15px",
                    }}
                >
                    {label}
                </Typography>
            </Grid>

            <Grid item {...breakpoints[1]}>
                {content}
            </Grid>

            <Grid item {...breakpoints[2]} />

            {/* Row divider */}
            <Grid
                item
                xs={12}
                sx={{
                    ...(noDivider ? { p: "0 !important" } : {}),
                }}
            >
                {!noDivider && <Divider />}
            </Grid>
        </>
    );
};

SectionRow.propTypes = {
    label: PropTypes.string,
    content: PropTypes.node,
    noDivider: PropTypes.bool,
    breakpoints: PropTypes.arrayOf(PropTypes.object),
};

export default SectionRow;
