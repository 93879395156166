import {FC, ReactElement, ReactNode} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ResponsiveIcon from "./ResponsiveIcon";
import { Stack } from "@mui/system";

interface PageHeaderProps {
    /*
     * Title of the page
     */
    title: string | ReactNode;
    /*
     * Display this element if it is defined, right side of the title line
     */
    toolbar?: ReactNode;
    /*
     * If it is true, mobile icon will be placed in title for marking the page is optimized for mobile too
     */
    responsive: boolean;
}

/**
 * Header element for MuiPage component
 */
const PageHeader: FC<PageHeaderProps> = ({ title, toolbar, responsive }): ReactElement => (
    <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
            m: 0,
            maxWidth: "100%",
            "& .MuiGrid-root": { pl: 0 },
        }}
    >
        {title && (
            <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography
                        id="pageTitle"
                        variant="h4"
                        sx={{
                            fontWeight: 400,
                            fontSize: {
                                xs: "24px",
                                md: "34px",
                            },
                        }}
                    >
                        {title}
                    </Typography>
                    {responsive && <ResponsiveIcon />}
                </Stack>
            </Grid>
        )}
        {toolbar && <Grid item>{toolbar}</Grid>}
    </Grid>
);

export default PageHeader;
