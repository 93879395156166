import * as yup from "yup";
import moment from "moment";
import translate from "flow-core-library-translator";

import translations from "../translations.json";

// eslint-disable-next-line require-jsdoc
const t = (key, params) => translate(translations, key, params);

const phoneRegExp = /^(\+|\d)(\d|-|\s){9,}$/;
const requiredHelperText = t("formHelperTexts.required");
const maxAllowedCharsHelperText50 = t("formHelperTexts.maxAllowedChars", {
    maxAllowedChars: 50,
});
const maxAllowedCharsHelperText35 = t("formHelperTexts.maxAllowedChars", {
    maxAllowedChars: 35,
});

/**
 * Convert empty string value "" to null
 * @param {string} value
 * @param {string} originalValue
 * @return {string | null}
 */
const emptyStringToNull = (value, originalValue) =>
    typeof originalValue === "string" && originalValue === "" ? null : value;

const emptyFormValues = {
    freightForwarderName: "",
    freightForwarderSubsidiary: "",
    driverFirstName: "",
    driverLastName: "",
    driverNationality: "",
    driverDateOfBirth: null,
    driverMobileNumber: "",
    codriverFirstName: "",
    codriverLastName: "",
    codriverNationality: "",
    codriverDateOfBirth: null,
    truckLicensePlate: "",
    truckNationality: "",
    totalPermissibleWeightKg: "",
    estimatedTimeOfArrival: null,
};

/**
 * Compile default form values based on stored form data
 * @param {object} storedData
 * @return {object}
 */
export const getFormDefaultValues = (storedData) => {
    // If no stored data, return with empty form values
    if (!storedData) {
        return emptyFormValues;
    }

    // Merge empty form values and stored form data.
    // Convert date values
    const defaultValues = {};

    for (const [key, emptyValue] of Object.entries(emptyFormValues)) {
        const storedValue =
            storedData[key] &&
            [
                "driverDateOfBirth",
                "codriverDateOfBirth",
                "estimatedTimeOfArrival",
            ].includes(key)
                ? moment(storedData[key])
                : storedData[key];

        defaultValues[key] = storedValue || emptyValue;
    }

    return defaultValues;
};

// eslint-disable-next-line require-jsdoc
export const formValidationSchema = ({
    allowedMinWeightOfDrive,
    allowedMaxWeightOfDrive,
}) => {
    const minWeight = allowedMinWeightOfDrive || 0;
    const maxWeight = allowedMaxWeightOfDrive || 800000;

    const invalidWeightHelperText = t("formHelperTexts.invalidWeight", {
        minWeight,
        maxWeight,
    });

    return yup.object().shape({
        freightForwarderName: yup
            .string()
            .max(50, maxAllowedCharsHelperText50)
            .required(requiredHelperText),
        freightForwarderSubsidiary: yup
            .string()
            .max(50, maxAllowedCharsHelperText50)
            .required(requiredHelperText),
        driverFirstName: yup
            .string()
            .max(35, maxAllowedCharsHelperText35)
            .required(requiredHelperText),
        driverLastName: yup
            .string()
            .max(35, maxAllowedCharsHelperText35)
            .required(requiredHelperText),
        driverNationality: yup.string().required(requiredHelperText),
        driverDateOfBirth: yup
            .date()
            .typeError(t("formHelperTexts.invalidFormat"))
            .required(requiredHelperText),
        driverMobileNumber: yup
            .string()
            .matches(phoneRegExp, t("formHelperTexts.invalidPhoneNumber"))
            .transform(emptyStringToNull)
            .nullable(),
        truckLicensePlate: yup.string().required(requiredHelperText),
        truckNationality: yup.string().required(requiredHelperText),
        totalPermissibleWeightKg: yup
            .number()
            .integer(t("formHelperTexts.invalidNumber"))
            .typeError(t("formHelperTexts.invalidNumber"))
            .min(minWeight, invalidWeightHelperText)
            .max(maxWeight, invalidWeightHelperText)
            .transform(emptyStringToNull)
            .nullable(),
        estimatedTimeOfArrival: yup
            .date()
            .typeError(t("formHelperTexts.invalidFormat"))
            .required(requiredHelperText),
        codriverDateOfBirth: yup
            .date()
            .nullable()
            .typeError(t("formHelperTexts.invalidFormat")),
    });
};
