import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import Layout from "./Layout/Layout";

/**
 * Custom Flow DateTimePicker component
 * @param {string} id Id of the input field
 * @param {string} label Label of the input field
 * @param {Date} value DateTime value of the picker
 * @param {function} onChange This function is called on time or date change
 * @param {object} inputProps Props for input field
 * @return {JSX.Element}
 */
const DateTimePicker = forwardRef(function DateTimePicker(
    { id, label, value, onChange, inputProps, ...rest },
    ref,
) {
    return (
        <MuiDateTimePicker
            id={id}
            value={value || null}
            onChange={onChange}
            orientation="landscape"
            slots={{
                layout: Layout,
                openPickerButton: (params) => (
                    <IconButton {...params}>
                        <CalendarTodayIcon />
                    </IconButton>
                ),
            }}
            slotProps={{
                field: {
                    fullWidth: true,
                    label: label,
                    ...inputProps,
                },
            }}
            ampm={false}
            closeOnSelect={false}
            inputRef={ref}
            {...rest}
        />
    );
});

DateTimePicker.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    inputProps: PropTypes.object,
};

export default DateTimePicker;
