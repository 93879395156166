import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {
    pickersLayoutClasses,
    PickersLayoutRoot,
    PickersLayoutContentWrapper,
} from "@mui/x-date-pickers/PickersLayout";
import { DateCalendar } from "@mui/x-date-pickers";

import DisplayTimePickerSwitch from "./DisplayTimePickerSwitch/DisplayTimePickerSwitch";
import TimePicker from "./TimePicker/TimePicker";
import ActionButtons from "./ActionButtons/ActionButtons";
import useMobileView from "../../../hooks/useMobileView";

/**
 * Custom layout component for DateTimePicker component
 * @param {object} props
 * @return {*}
 */
const Layout = (props) => {
    const { value, onChange } = props;
    const [timeAdjust, setTimeAdjust] = useState(false);
    const isMobileView = useMobileView();

    return (
        <PickersLayoutRoot ownerState={props}>
            <PickersLayoutContentWrapper
                className={pickersLayoutClasses.contentWrapper}
            >
                <Grid container direction="row">
                    <Grid
                        item
                        xs
                        container
                        direction="column"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <Grid item>
                            <DateCalendar value={value} onChange={onChange} />
                        </Grid>

                        {/* In mobile view we need to display the TimePicker in other place */}
                        {timeAdjust && isMobileView && (
                            <Grid item xs sx={{ width: "100%" }}>
                                <TimePicker value={value} onChange={onChange} />
                            </Grid>
                        )}

                        <Grid item>
                            <DisplayTimePickerSwitch
                                checked={timeAdjust}
                                onChange={(value) => setTimeAdjust(value)}
                            />
                        </Grid>

                        <Grid item>
                            <ActionButtons layoutProps={props} />
                        </Grid>
                    </Grid>

                    {/* In mobile view we need to display TimePicker here */}
                    {timeAdjust && !isMobileView && (
                        <Grid item xs>
                            <TimePicker value={value} onChange={onChange} />
                        </Grid>
                    )}
                </Grid>
            </PickersLayoutContentWrapper>
        </PickersLayoutRoot>
    );
};

Layout.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

export default Layout;
