import React from "react";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";

/** Alert element component for MuiPage component
 * @param {string | object} alert
 * @param {string} id Html id of alert message
 * @param {string} variant
 * @return {JSX.Element}
 */
const AlertElement = ({ alert, id, variant = "standard"}) => {
    if (["string", "number"].includes(typeof alert)) {
        return (
            <MuiAlert id={id} severity="error" variant={variant}>
                {alert}
            </MuiAlert>
        );
    }

    if (typeof alert === "object") {
        return (
            <MuiAlert id={id} severity={alert.severity?.toLowerCase() || "error"}>
                {alert.text || ""}
            </MuiAlert>
        );
    }

    console.error("Invalid Alert component input.");
    return null;
};

AlertElement.propTypes = {
    alert: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.string,
    variant: PropTypes.string,
};

export default AlertElement;
