import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
    Button,
    Backdrop,
    CircularProgress,
    ThemeProvider,
} from "@mui/material";
import translate, { translateCountryNames } from "flow-core-library-translator";
import PreregistrationForm from "./components/PreregistrationForm";
import MuiPage from "../../flow-core-components-shared/src/components/MuiPage/MuiPage";
import Dialog from "../../flow-core-components-shared/src/components/Dialog/Dialog";
import flowMuiTheme from "../../flow-core-components-shared/src/utils/flowMuiTheme";

import t from "./translations.json";
import CustomHeader from "./components/CustomHeader";
import Footer from "./components/Footer";
import { getPageMetaData, getPreRegData } from "./api/Api";

/**
 * Drive preregistration from component
 * @return {JSX.Element}
 */
const DrivePreregistration = () => {
    const [view, setView] = useState("loading");
    const [pageData, setPageData] = useState();
    const [pageMetaData, setPageMetaData] = useState();
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const urlLinkId = new URL(document.location).searchParams.get("id");

    const fetchFormData = useCallback((id) => {
        // Check if the link id exists in the url
        getPageMetaData()
            .then(({ data }) => {
                // Tranlate country names
                setPageMetaData({
                    ...data,
                    ...(data.countries
                        ? { countries: translateCountryNames(data.countries) }
                        : {}),
                });
            })
            .catch((error) => {
                console.error("Error at loading page data.", error);
                setShowErrorDialog(true);
            });

        // If id is there, fetch the page and form data
        if (id) {
            getPreRegData(id)
                .then(({ data }) => {
                    setPageData(data);
                })
                .catch((error) => {
                    console.error("Error at loading form data.", error);
                    setShowErrorDialog(true);
                });
        } else {
            setView("invalidLink");
        }
    }, []);

    const handleReturnFromSubmittedPage = useCallback(() => {
        setView("loading");
        fetchFormData(urlLinkId);
    }, []);

    useEffect(() => {
        fetchFormData(urlLinkId);
    }, []);

    useEffect(() => {
        // Check link validity
        if (pageData && pageMetaData) {
            setView(pageData.valid ? "form" : "invalidLink");
        }
    }, [pageData, pageMetaData]);

    const page = useMemo(() => {
        if (view === "formSubmitted") {
            return {
                title: translate(t, "pageTitles.formSubmitted"),
                content: (
                    <Button
                        onClick={handleReturnFromSubmittedPage}
                        id="btnBackToForm"
                    >
                        {translate(t, "labels.backToPeregistration")}
                    </Button>
                ),
            };
        }

        if (view === "invalidLink") {
            return {
                title: translate(t, "pageTitles.invalidLink"),
                content: null,
            };
        }

        if (pageData && pageMetaData && view === "form") {
            return {
                title: `${translate(t, "pageTitles.form")} ${
                    pageMetaData.siteName || ""
                }`,
                content: (
                    <PreregistrationForm
                        driveDetails={{ ...pageData, ...pageMetaData }}
                        onSuccesSubmit={() => setView("formSubmitted")}
                        urlLinkId={urlLinkId}
                    />
                ),
            };
        }
    }, [view, pageData]);

    return (
        <ThemeProvider theme={flowMuiTheme}>
            {["form", "formSubmitted", "invalidLink"].includes(view) ? (
                <MuiPage
                    hideTopDivider
                    variant="flow5"
                    HeaderComponent={CustomHeader}
                    headerProps={{
                        title: page.title,
                    }}
                    footer={
                        pageMetaData && (
                            <Footer
                                legalNoticeUrl={pageMetaData.legalNoticeUrl}
                                dataprotectionGuidelinesUrl={
                                    pageMetaData.dataprotectionGuidelinesUrl
                                }
                                productRevision={pageMetaData.productRevision}
                            />
                        )
                    }
                >
                    {page.content}
                </MuiPage>
            ) : (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={view === "loading"}
                >
                    <Dialog
                        open={showErrorDialog}
                        text={translate(t, "messages.loadingError")}
                        variant="warning"
                        onClose={() => setShowErrorDialog(false)}
                    />
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </ThemeProvider>
    );
};

export default DrivePreregistration;
