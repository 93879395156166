import React from "react";
import PropTypes from "prop-types";
import { Link } from "@mui/material";
import translate from "flow-core-library-translator";

import translations from "../../translations.json";

// eslint-disable-next-line require-jsdoc
const t = (key) => translate(translations, `FormLayout.${key}`);

/**
 * Show more/less button
 * @param {*} props
 * @return {JSX.Element}
 */
const ChangeViewButton = ({ advancedSearch, onClick }) => {
    return (
        <Link
            id="advancedSearchShowAndHideLink"
            underline="none"
            sx={{
                fontFamily: "Roboto",
                textTransform: "uppercase",
                fontWeight: 500,
                cursor: "pointer",
                "&:hover": {
                    textDecoration: "underline",
                },
            }}
            onClick={onClick}
        >
            {t(advancedSearch ? "lessSearchParams" : "moreSearchParams")}
        </Link>
    );
};

ChangeViewButton.propTypes = {
    advancedSearch: PropTypes.bool,
    onClick: PropTypes.func,
};

export default ChangeViewButton;
