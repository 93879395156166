import React, { useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import translate from "flow-core-library-translator";

import t from "../../../translations";
import useMobileView from "../../../../hooks/useMobileView";
import TimePickerUnit from "./TimePickerUnit";

/**
 * TimePicker from DateTimePicker component
 * @param {Date} value
 * @param {function} onChange
 * @return {JSX.Element}
 */
const TimePicker = ({ value, onChange }) => {
    const isMobileView = useMobileView();

    // Get part of a time value in 24h format
    const getTimePart = useCallback(
        (unit) => {
            if (value) {
                return parseInt(moment(value).format(unit));
            } else {
                return 0;
            }
        },
        [value]
    );

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                paddingRight: 4,
                paddingLeft: 4,
            }}
        >
            <Grid item>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "150%",
                        mt: 2.5,
                    }}
                >
                    {translate(t, "dateTimePicker.labels.time")}
                </Typography>
            </Grid>

            <Grid item>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{ mt: isMobileView ? 0 : 8 }}
                >
                    <TimePickerUnit
                        disabled={!value}
                        stepSpeed={100}
                        numberOfSlowSteps={4}
                        minValue={0}
                        maxValue={23}
                        value={getTimePart("HH")}
                        onChange={(hour) =>
                            onChange(moment(value).set("hour", hour))
                        }
                    />
                    <TimePickerUnit
                        disabled={!value}
                        stepSpeed={100}
                        numberOfSlowSteps={4}
                        minValue={0}
                        maxValue={59}
                        value={getTimePart("mm")}
                        onChange={(hour) =>
                            onChange(moment(value).set("minute", hour))
                        }
                    />
                </Stack>
            </Grid>
        </Grid>
    );
};

TimePicker.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

export default TimePicker;
