import { createTheme } from "@mui/material//styles";
import { alertClasses } from "@mui/material/Alert";

const flowTheme = createTheme({
    palette: {
        primary: {
            main: "#0077B8",
            light: "#00B0F0",
        },
        secondary: {
            main: "#e0e0e0",
        },
    },
    typography: {
        h6: {
            fontSize: "1.25rem",
            fontWeight: 500,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    padding: "8px 22px",
                    gap: 8,
                    // prettier-ignore
                    ...(ownerState.variant === "outlined" &&
                        ownerState.color === "primary" && {
                        backgroundColor: "#fff",
                    }),
                }),
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: "transparent",
            },
            styleOverrides: {
                root: {
                    fontFamily: "Roboto",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: 1.5,
                },
                message: {
                    fontFamily: "Roboto",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: 1.5,
                },
            },
            variants: [
                {
                    props: { variant: "transparent" },
                    style: ({ theme }) => ({
                        border: "none",
                        paddingLeft: 0,
                        paddingRight: 0,
                        [`&.${alertClasses.colorError}`]: {
                            color: theme.palette.error.main,
                        },
                        [`&.${alertClasses.colorWarning}`]: {
                            color: theme.palette.warning.main,
                        },
                        [`&.${alertClasses.colorSuccess}`]: {
                            color: theme.palette.success.main,
                        },
                        [`&.${alertClasses.colorInfo}`]: {
                            color: theme.palette.info.main,
                        },
                    }),
                },
            ],
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    outline: "unset",
                },
            },
        },
        MuiCheckbox: {},
        MuiInputBase: {
            styleOverrides: {
                input: {
                    backgroundColor: "#fff",
                },
            },
        },
    },
});

export default flowTheme;
