import React from "react";
import { Box } from "@mui/material";
import MobileIcon from "@mui/icons-material/MobileFriendly";

/** Mobile icon for page title, mark the page mobile friendly
 * @return {JSX.Element}
 */
const ResponsiveIcon = () => {
    return (
        <Box
            sx={{
                backgroundColor: "rgba(0, 0, 0, .04)",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexWrap: "wrap",
                padding: 1,
                alignSelf: "center",
            }}
        >
            <MobileIcon color="primary" />
        </Box>
    );
};

export default ResponsiveIcon;
