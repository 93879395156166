import {FC, ReactElement} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MuiBackdrop from "@mui/material/Backdrop";

interface BackdropProps {
    open: boolean;
}

/**
 * Backdrop component
 */
const Backdrop: FC<BackdropProps> = ({ open }): ReactElement => {
    return (
        <MuiBackdrop
            sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.modal + 1,
            }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </MuiBackdrop>
    );
};

export default Backdrop;
