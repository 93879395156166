import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import translate from "flow-core-library-translator";

import translations from "../../../translations.json";

// eslint-disable-next-line require-jsdoc
const t = (key) => translate(translations, `dateTimePicker.${key}`);

/**
 * Switch component for display time picker component
 * @return {JSX.Element}
 */
const DisplayTimePickerSwitch = ({ checked, onChange }) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={(event) => onChange(event.target.checked)}
                />
            }
            label={t("labels.customTime")}
        />
    );
};

DisplayTimePickerSwitch.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default DisplayTimePickerSwitch;
